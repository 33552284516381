import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AmplifyService } from "aws-amplify-angular";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private token;

	constructor(private amplifyService: AmplifyService, private cookiesService: CookieService, private router: Router) {
		this.amplifyService.authStateChange$
			.subscribe(authState => {
				if (!!authState && authState.state === 'signedIn') {

					this.token = authState.user.signInUserSession.getIdToken().getJwtToken();
				} else {
					this.token = null;
				}
			});
	}

	public isAuthenticatedGuard(): Observable<boolean> {
		const tokenValue = this.cookiesService.get('access_token');

		return of(!!tokenValue);
	}

	signRequest(headers: HttpHeaders): HttpHeaders {
		return headers.set('Authorization', 'Bearer ' + this.token);
	}
	signRequestOffBearer(headers: HttpHeaders): HttpHeaders {
		return headers.set('Authorization', this.token);
	}

}

import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { LayoutService } from "../../../services/layout.service";
import { MainProductServices } from "../../../services/main-product.service";
import { ThemeService } from "../../../services/theme.service";

@Component({
	selector: "app-admin-layout",
	templateUrl: "./admin-layout.template.html"
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
	public isModuleLoading: boolean = false;
	private moduleLoaderSub: Subscription;
	private layoutConfSub: Subscription;
	private routerEventSub: Subscription;
	public scrollConfig = {};
	public layoutConf: any = {};

	constructor(private router: Router, public themeService: ThemeService, private layout: LayoutService, private typeService: MainProductServices) {
		// Close sidenav after route change in mobile
		this.routerEventSub = router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange: NavigationEnd) => {
			this.layout.adjustLayout({ route: routeChange.url });
		});

	}

	ngOnInit() {
		this.layoutConf = this.layout.layoutConf;
		// FOR MODULE LOADER FLAG
		this.moduleLoaderSub = this.router.events.subscribe(event => {
			if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
				this.isModuleLoading = true;
			}
			if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
				this.isModuleLoading = false;
			}
		});
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.layout.adjustLayout(event);
	}

	scrollToTop(selector: string) {
		if (document) {
			const element = <HTMLElement>document.querySelector(selector);
			element.scrollTop = 0;
		}
	}

	ngOnDestroy() {
		if (this.moduleLoaderSub) {
			this.moduleLoaderSub.unsubscribe();
		}
		if (this.layoutConfSub) {
			this.layoutConfSub.unsubscribe();
		}
		if (this.routerEventSub) {
			this.routerEventSub.unsubscribe();
		}
	}

	closeSidebar() {
		this.layout.publishLayoutChange({
			sidebarStyle: "closed"
		});
	}
}

<div id="static-params-content">
	<div class="data-content  mr-1">
		<div *ngIf="!!branch">
			<mat-form-field>
				<input matInput placeholder="Ramo" id="branch" name="branch" [value]="branch" readonly/>
			</mat-form-field>
		</div>
		<div class=" ml-1" *ngIf="!!product" [class.mr-1]="!!module">
			<mat-form-field>
				<input matInput placeholder="Producto" id="product" name="product" [value]="product" readonly/>
			</mat-form-field>
		</div>
		<div *ngIf="!!module">
			<mat-form-field>
				<input matInput placeholder="Módulo" id="module" name="module" [value]="module" readonly/>
			</mat-form-field>
		</div>
		<ng-content select="[customParams]">

		</ng-content>
	</div>
	<div>
		<ng-content></ng-content>
	</div>
</div>


<div id="file-selector-container">
    <label>{{file?.name}}</label>
    <button mat-button color="primary" (click)="procesarPdf()">
        <mat-icon>file_upload</mat-icon>
    </button>
    <button mat-button class="btn btn-enabled" [disabled]="!file || error"
            [ngClass]="{'btn-disabled': !file || error}" (click)="uploadFile()">
        Procesar
    </button>
    <h4 *ngIf="error" class="error"> Este documento ya ha sido procesado</h4>
    <input
            type="file"
            id="fileUpload"
            name="fileUpload"
            accept="{{accept}}"
            style="display:none;"
    />
</div>


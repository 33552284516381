
import * as moment from "moment";

export function customDateToString(date = new Date (Date.now ())) {
	return `${date.getDate () < 10 ? '0' + date.getDate () : date.getDate ()}/${(date.getMonth () + 1) < 10 ? '0' + (date.getMonth () + 1) : (date.getMonth () + 1)}/${date.getFullYear ()}`;
}

export function customStringToDate(date: string, separator = '/') {
	const values = date.split (separator);
	const newObj = values[1] + separator + values[0] + separator + values[2];
	return new Date (newObj);
}

export function fechaInicioMes(mes) {
	return new Date (new Date (Date.now ()).getFullYear (), mes, 1);
}

export function fechaFinMes(mes) {
	return new Date (new Date (Date.now ()).getFullYear (), mes, 0);
}

export function calculoEdad(date) {
	const timeDiff = Math.abs (Date.now () - date);
	return Math.round((timeDiff / (1000 * 3600 * 24)) / 365.25);
}
export function calculoEdadCronologica(date: number) {
	/* const timeDiff = Math.abs 	(Date.now() - date);
	return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25); */
	const hoy = moment();
	const birthDay = moment(date);
	return hoy.diff(birthDay, 'years');
}
export function convertStringToNumberDate(date: string): number {
	return (new Date(date)).getTime();
}

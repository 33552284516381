import { Component, Input } from "@angular/core";

@Component({
	selector: 'app-common-layout',
	templateUrl: './common-layout.component.html',
	styleUrls: ['./common-layout.component.scss']
})
export class CommonLayoutComponent {
	/**
	 * Define la alineacion donde se mostrara el contenido, se usa para cuando la vista no ocupara el 100$
	 * del ancho de la ventana, especificar en que parte se vera el contenido
	 * @default start
	 *
	 * Posibles valores :
	 * flex-start: Los componentes se mostraran a la izquierda
	 * center: Los componentes se mostraran al centro
	 * flex-end: Los componentes se mostraran a la derecha
	 */
	@Input() align = 'flex-start';

	/**
	 * Define el ancho del contenido
	 * @default 100%
	 */
	@Input() width = '100%';

}

import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: 'app-file-selector',
	templateUrl: './file-selector.component.html',
	styleUrls: ['./file-selector.component.scss']
})
export class FileSelectorComponent {
	@Input() text = 'Upload';
	@Input() param = 'file';
	@Input() target = '';
	@Input() accept = 'text/*';
	file: File;
	@Output() complete = new EventEmitter<File>();
	@Input() selected: string[];
	error = false;

	constructor(private _http: HttpClient) {
	}

	procesarPdf() {
		const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;

		fileUpload.onchange = () => {
			const f = fileUpload.files[0];
			this.error = this.selected.filter(s => s === f.name).length > 0;
			this.file = fileUpload.files[0];
		};
		fileUpload.click();
	}

	uploadFile() {
		if (!this.error) {
			this.complete.emit(this.file);
			this.file = null;
		}
	}


}

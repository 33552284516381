import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { LayoutService } from "../../services/layout.service";
import { RoutePartsService } from "../../services/route-parts.service";

@Component({
	selector: "app-breadcrumb",
	templateUrl: "./breadcrumb.component.html",
	styleUrls: ["./breadcrumb.component.scss"]
})
export class BreadcrumbComponent implements OnDestroy {
	routeParts: any[];
	routerEventSub: Subscription;

	constructor(
		private router: Router,
		private routePartsService: RoutePartsService,
		private activeRoute: ActivatedRoute,
		public layout: LayoutService
	) {
		this.routerEventSub = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			this.routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
		});
	}

	ngOnDestroy() {
		if (this.routerEventSub) {
			this.routerEventSub.unsubscribe();
		}
	}
}

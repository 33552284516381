import { Component, Input } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
	selector: 'app-static-params',
	templateUrl: './static-params.component.html',
	styleUrls: ['./static-params.component.scss']
})
export class StaticParamsComponent  {
	@Input() branch = environment.paramsConfig.branch.description;
	@Input() product = environment.paramsConfig.product.description;
	@Input() module;

}

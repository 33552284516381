import { Component, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MainProductServices } from "../../services/main-product.service";

@Component({
	selector: "app-tarifarios",
	templateUrl: "./tarifarios.component.html"
})

export class TarifariosComponent implements OnInit {

	@Input() tarifarioPanel;
	data: any[];
	visible: boolean = false;

	tarifario = [{
		message: "Tarifario SCTR",
		type: "SCTR",
		icon: "person_pin",
		time: "",
		route: "/dashboard",
		color: "primary"
	}, {
		message: "Tarifario SOAT",
		type: "SOAT",
		icon: "directions_car",
		time: "",
		route: "/dashboard",
		color: "primary"
	}];

	constructor(private router: Router, private typeService: MainProductServices) {
	}

	ngOnInit(): void {
		this.router.events.subscribe((routeChange) => {
			if (routeChange instanceof NavigationEnd) {
				this.tarifarioPanel.close();
			}
		});
	}

	togglrTarifario(change: any) {
		this.typeService.saveMainProduct(change);
		if (window.location.pathname === "/dashboard") {
			window.location.reload();
		} else {
			this.router.navigate(["/dashboard"]);
		}
	}

}

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
// @ts-ignore
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AlertService } from "../../../shared/services/alert/alert.service";
import { AuthService } from "../../auth/auth.service";
import { DATASOURCE_ROOT_CONFIG, DatasourceConfig } from "../datasource.config";
import { Datasource, DatasourceAPI, DatasourceOption } from "./datasource.model";

@Injectable({
	providedIn: 'root'
})
export class DatasourceService {

	constructor(
		private authService: AuthService,
		private _http: HttpClient,
		@Inject(DATASOURCE_ROOT_CONFIG) private _dsConfig: DatasourceConfig,
		private alertService: AlertService
	) {
		this._setBaseHrefToUrls();
	}

	request(id: string, action: string = '', options: DatasourceOption = {},
					staticOptions: { onError?: BehaviorSubject<any>, showError?: boolean } = {showError: true}): Observable<any> {
		const ds = this._dsConfig.datasources[id];

		if (ds.items) {
			return of(ds.items);
		}

		const api = this.getDatasourceApi(id, action);
		const url = this._resolveUrl(api, options);

		const requestOptions = this._resolveReqOptions(options);
		return this._http.request(api.method, url, requestOptions)
			.pipe(
				tap((response: any) => {
					if (!!response.code && response.code !== 200) {
						if (response.message) {
							this.alertService.pushError('Error', response.message);
						}
						throw response;
					}
					if (!!options.msg && !!options.msg['OK']) {
						this.alertService.pushError('Correcto', options.msg['OK']);
					}

				}),
				catchError((err: HttpErrorResponse | any) => {

					if (staticOptions.onError) {
						staticOptions.onError.next(true);
					}
					if (staticOptions.showError) {
						let msg;

						if (!!options.msg && !!options.msg['ERROR']) {
							msg = options.msg['ERROR'];
						} else {
							msg = !!err.data ? err.data : `Error en la petición ${api.name}`;
						}

						this.alertService.pushError('Error', msg );
					}

					throw err;
				})
			);
	}


	get(model: string): Datasource {
		return this._dsConfig.datasources[model];
	}


	getDatasourceApi(model: string, action: string): DatasourceAPI {
		return this.get(model).api[action];
	}


	baseHref() {
		return this._dsConfig.baseAbsoluteUrl;
	}


	_resolveUrl(api: DatasourceAPI, options: DatasourceOption): string {
		const url = api.url;

		if (!options.urlParams) {
			// Si no tiene configurado branch o product, o no esta en la url,  devuelve la url , sino le agrega
			// un objeto urlParams para configurarlo
			if (!api.product && !api.branch && url.indexOf('{branch}') < 0 && url.indexOf('{product}') < 0) {
				return url;
			} else {
				options.urlParams = {};
			}
		}

		// Si la url tiene el parametro branch o el parametro modulo se asignan desde el enviroment
		if (!!api.branch || url.indexOf('{branch}') > -1) {
			options.urlParams = {...options.urlParams, branch: environment.paramsConfig.branch.code};
		}
		if (!!api.product || url.indexOf('{product}') > -1) {
			options.urlParams = {...options.urlParams, product: environment.paramsConfig.product.code};
		}
		return this._resolveUrlParams(url, options.urlParams);
	}


	private _resolveParams(options: DatasourceOption): HttpParams {

		let config = {};
		const queryParams = options.queryParams;

		if (options.queryParams) {
			config = Object.assign({}, config, queryParams);
		}

		let params = new HttpParams();
		const keys = Object.keys(config);

		for (let i = 0; i < keys.length; i++) {
			const newParam: any = config[keys[i]];
			params = params.append(keys[i], newParam);
		}

		return params;
	}

	private _resolveHeaders(file = false): HttpHeaders {

		let headers = this.authService.signRequest(new HttpHeaders());
		if (!file) {
			headers = headers.set('Content-Type', 'application/json');
		}

		return headers;
	}

	private _resolveUrlParams(url: string, urlParams: any) {

		return Object.keys(urlParams).reduce((_url, key) => {
			return _url.replace(new RegExp('{' + key + '}', 'g'), urlParams[key]);
		}, url);
	}

	private _resolveBodyParam(options: DatasourceOption) {
		return options.body || {};
	}

	private _resolveReqOptions(options: DatasourceOption, file = false) {

		return {
			params: this._resolveParams(options),
			headers: this._resolveHeaders(file),
			body: this._resolveBodyParam(options),
			responseType: options.responseType,
			observe: options.observe
		};

	}

	private _setBaseHrefToUrls() {

		const absUrl = this.baseHref();
		const dsIds = Object.keys(this._dsConfig.datasources);


		for (let i = 0; i < dsIds.length; i++) {
			const dataSource = this.get(dsIds[i]);
			const api = this.get(dsIds[i]).api;

			if (!api) {
				continue;
			}

			const actions = Object.keys(api);
			let action;
			for (let j = 0; j < actions.length; j++) {
				action = api[actions[j]];

				if (action.url.indexOf(absUrl) < 0) {
					if (!action.skip) {
						action.url = dataSource.url + action.url;
					}
				}

			}
		}
	}

}

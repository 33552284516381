import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NgxSpinnerService} from "ngx-spinner";
import {finalize} from "rxjs/operators";

/**
 * @author Jesús Yanser Vega Labarcena
 * 20-01-2021
 *Interceptor para el manejo del spinner cuando hay multiples peticiones
 */

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	private count = 0;

	constructor(private _spinner: NgxSpinnerService) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.count === 0) {
			this._spinner.show();
		}

		this.count++;

		return next.handle(request)
			.pipe(
				finalize(() => {
					this.count--;
					if (this.count === 0) {
						this._spinner.hide();
					}
				}));
	}
}

import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
// importar locales
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GestureConfig } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { BrowserModule, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import Auth from "@aws-amplify/auth";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";

import { SnotifyModule, SnotifyService, ToastDefaults } from "ng-snotify";
import { ContextMenuModule } from "ngx-contextmenu";
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { rootRouterConfig } from "./app.routing";
import { CustomMatPaginationIntl } from "./core/base/customMatPaginationIntl";
import { DatasourceModule } from "./core/datasource/datasource.module";
import { DATASOURCE_CONFIG } from "./core/datasource/datasource.url.config";

import { LoadingInterceptor } from "./shared/interceptors/loading.interceptor";

import { MaterialModule } from "./shared/modules/material.module";
import { MainProductServices } from "./shared/services/main.product.service";
import { VersionCheckService } from "./shared/services/version-check.service";
import { SharedModule } from "./shared/shared.module";

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');


Auth.configure(environment.aws);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelPropagation: true,
	suppressScrollX: true
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		HttpClientModule,
		PerfectScrollbarModule,
		RouterModule.forRoot(rootRouterConfig, {
			useHash: true
		}),
		MaterialModule,
		NgxSpinnerModule,
		DatasourceModule.forRoot(DATASOURCE_CONFIG),
		environment.production ? [] : AkitaNgDevtools,
		SnotifyModule,
		ContextMenuModule.forRoot(),
		AmplifyAngularModule
	],
	declarations: [
		AppComponent
	],
	entryComponents: [],
	providers: [
		AmplifyService,
		MainProductServices,
		{provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
		{provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
		{provide: 'SnotifyToastConfig', useValue: ToastDefaults},
		{
			provide: MatPaginatorIntl,
			useClass: CustomMatPaginationIntl
		},
		SnotifyService,
		VersionCheckService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoadingInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

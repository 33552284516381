import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";

import { HotTableModule } from "@handsontable/angular";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
// ALL TIME REQUIRED
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CommonLayoutComponent } from "./components/common-layout/common-layout.component";
import { FileSelectorComponent } from "./components/file-selector/file-selector.component";
import { HandsontableComponent } from "./components/handsontable/handsontable.component";
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { AdminLayoutComponent } from "./components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { StaticParamsComponent } from "./components/static-params/static-params.component";
import { TarifariosComponent } from "./components/tarifarios/tarifarios.component";
// SERVICES
// DIRECTIVES
// PIPES
import { AppComfirmComponent } from "./services/app-confirm/app-confirm.component";
import { AppConfirmService } from "./services/app-confirm/app-confirm.service";
import { FileExportService } from "./services/file.export.service";
import { LayoutService } from "./services/layout.service";
import { MainProductServices } from "./services/main-product.service";
import { RoutePartsService } from "./services/route-parts.service";
import { ThemeService } from "./services/theme.service";

const classesToInclude = [
	SidenavComponent,
	TarifariosComponent,
	AdminLayoutComponent,
	AuthLayoutComponent,
	BreadcrumbComponent,
	AppComfirmComponent,
	FileSelectorComponent,
	CommonLayoutComponent,
	HandsontableComponent,
	StaticParamsComponent
];
const materialToInclude = [
	MatSidenavModule,
	MatListModule,
	MatTooltipModule,
	MatOptionModule,
	MatSelectModule,
	MatMenuModule,
	MatSnackBarModule,
	MatGridListModule,
	MatToolbarModule,
	MatIconModule,
	MatButtonModule,
	MatRadioModule,
	MatCheckboxModule,
	MatCardModule,
	MatProgressSpinnerModule,
	MatRippleModule,
	MatDialogModule,
	PerfectScrollbarModule,
	MatTableModule,
	MatPaginatorModule,
	MatSortModule,
	MatFormFieldModule,
	MatInputModule,
	MatTabsModule
];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		HotTableModule.forRoot(),
		FlexLayoutModule,
		materialToInclude

	],
	entryComponents: [AppComfirmComponent],
	providers: [
		ThemeService,
		LayoutService,
		MainProductServices,
		RoutePartsService,
		AppConfirmService,
		FileExportService
	],
	declarations: [classesToInclude],
	exports: [...classesToInclude, ...materialToInclude]
})
export class SharedModule {
}

<div class="text-center mat-bg-primary pt-1 pb-1">
	<h6 class="m-0">Seleccione Tarifario</h6>
</div>
<mat-nav-list class="notification-list" role="list">
	<!-- Notification item -->
	<mat-list-item *ngFor="let n of tarifario" class="notific-item" role="listitem" (click)="togglrTarifario(n.type)"
								 routerLinkActive="open">
		<mat-icon [color]="n.color" class="notific-icon mr-1">{{ n.icon }}</mat-icon>
		<a [routerLink]="[n.route || '/dashboard']">
			<div class="mat-list-text">
				<h4 class="message" (click)="togglrTarifario(n.type)">{{ n.message }}</h4>
				<small class="time text-muted">{{ n.time }}</small>
			</div>
		</a>
	</mat-list-item>
</mat-nav-list>

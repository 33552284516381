import { Routes } from "@angular/router";
import { AuthGuard } from "./core/auth/auth.guard";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";

export const rootRouterConfig: Routes = [
	{
		path: "",
		redirectTo: "quotation",
		pathMatch: "full"
	},
	{
		path: "",
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: "dashboard",
				loadChildren: () => {
					return import("./views/dashboard/dashboard.module").then((m) => m.DashboardModule);
				},
				data: { title: "Inicio", breadcrumb: "INICIO" }
			},
			{
				path: "quotation",
				loadChildren: () => {
					return import("./views/quotation/quotation.module").then((m) => m.QuotationModule);
				},
				data: { title: "Cotizador", breadcrumb: "Cotizador" }
			}
		]
	},
	{
		path: "**",
		redirectTo: "quotation"
	}
];

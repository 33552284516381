import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { HotTableRegisterer } from "@handsontable/angular";

@Component({
	selector: 'app-handsontable',
	templateUrl: './handsontable.component.html',
	styleUrls: ['./handsontable.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandsontableComponent implements OnInit, OnChanges {

	private hot = new HotTableRegisterer();
	settings;

	@Output() onEdit = new EventEmitter();
	@Input() instance = 'custom-hot-table';

	@Input() width = '100%';
	@Input() heigth = 200;
	@Input() colWidths = 100;

	@Input() colHeaders = [];

	@Input() data = [];

	@Input() columnsConfig = [];

	constructor() {
		this.populate();
	}

	ngOnInit(): void {
		this.populate();
	}

	ngOnChanges() {
		this.populate();
	}

	populate() {
		const that = this;
		this.settings = {
			data: that.data,
			width: that.width,
			height: that.heigth,
			colHeaders: that.colHeaders,
			stretchH: 'all',
			fixedRowsTop: 0,
			fixedColumnsLeft: 0,
			rowHeaders: false,
			manualColumnMove: false,
			manualRowMove: false,
			columnSorting: false,
			manualColumnResize: false,
			colWidths: that.colWidths,
			wordWrap: true,
			comments: false,
			outsideClickDeselects: false,
			className: 'custom-handsontable',
			beforeChange: function (changes, source) {
				const column = changes[0][1];
				const data = changes[0][3];
				const config = that.columnsConfig[column];
				if (!!config) {
					if (!!config['valueFn']) {
						changes[0][3] = config['valueFn'](data);
					}
				}


			},
			afterChange: function (changes, source) {
				if (!!changes) {
					that.onEdit.emit(changes);
				}
			},
			beforeRender: () => {
			},
			afterLoadData: () => {
			}
		};

		if (!!this.columnsConfig && this.columnsConfig.length > 0) {
			this.settings.columns = this.columnsConfig;
		}
		if (this.hot) {
			setTimeout(() => {
				this.hot.getInstance(this.instance).updateSettings({className: ""}, true);
				this.hot.getInstance(this.instance).updateSettings(this.settings, false);
				this.hot.getInstance(this.instance).render();
				this.hot.getInstance(this.instance).deselectCell();
				this.hot.getInstance(this.instance).validateCells();
			}, 0);
		}
	}

	log(...args) {
		console.log('', ...args);
	}

}

/** Column configuration example
 * [
				{
					type: 'text',
					editor: false
				},
				{
					type: 'numeric',
					editor: 'numeric',
					numericFormat: {
						pattern: '0,0.0000',
						culture: 'en-US' // this is the default culture, set up for USD
					},
					allowEmpty: false
				},
				{
					type: 'numeric',
					editor: 'numeric',
					numericFormat: {
						pattern: '0,0.0000',
						culture: 'en-US' // this is the default culture, set up for USD
					},
					allowEmpty: false
				},
				{
					type: 'numeric',
					editor: 'numeric',
					numericFormat: {
						pattern: '0,0.0000',
						culture: 'en-US' // this is the default culture, set up for USD
					},
					allowEmpty: false
				},
				{
					type: 'numeric',
					editor: 'numeric',
					numericFormat: {
						pattern: '0,0.0000',
						culture: 'en-US' // this is the default culture, set up for USD
					},
					allowEmpty: false
				},
			]
 */

import {environment} from "../../../environments/environment";

export const paramsTypeModel = [
	{
		id: 1,
		description: 'Tipos de Trabajadores',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 2,
		description: 'Tipos de Recargos',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 3,
		description: 'Moneda',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 9,
		description: 'Pesos de la columna Mix de Tabla de Mortalidad',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 12,
		description: 'Tope Remuneración',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 13,
		description: 'Tope Edad',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 14,
		description: 'Porcentaje de Cesión',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	},
	{
		id: 15,
		description: 'Reaseguradora por defecto',
		product: {
			branch: {
				id: environment.paramsConfig.branch.code,
				description: environment.paramsConfig.branch.description
			},
			id: environment.paramsConfig.product.code,
			description: environment.paramsConfig.product.description
		},
		module: environment.moduleConfig.params,
	}
];


// {
// 	id: 4,
// 		description: 'Ponderación por edades',
// 	product: {
// 	branch: {
// 		id: environment.paramsConfig.branch.code,
// 			description: environment.paramsConfig.branch.description
// 	},
// 	id: environment.paramsConfig.product.code,
// 		description: environment.paramsConfig.product.description
// },
// 	module: environment.moduleConfig.params,
// }, {
// 	id: 5,
// 		description: 'Factores de Ponderación/Reajuste de Tabla de Mortalidad',
// 		product: {
// 		branch: {
// 			id: environment.paramsConfig.branch.code,
// 				description: environment.paramsConfig.branch.description
// 		},
// 		id: environment.paramsConfig.product.code,
// 			description: environment.paramsConfig.product.description
// 	},
// 	module: environment.moduleConfig.params,
// }, {
// 	id: 6,
// 		description: 'Factores de Ponderación/Reajuste de Tasas de Riesgo de Cobertura',
// 		product: {
// 		branch: {
// 			id: environment.paramsConfig.branch.code,
// 				description: environment.paramsConfig.branch.description
// 		},
// 		id: environment.paramsConfig.product.code,
// 			description: environment.paramsConfig.product.description
// 	},
// 	module: environment.moduleConfig.params,
// }, {
// 	id: 7,
// 		description: 'Factores de Ponderación/Reajuste de Tasas de Riesgo Por Tipos de Trabajadores',
// 		product: {
// 		branch: {
// 			id: environment.paramsConfig.branch.code,
// 				description: environment.paramsConfig.branch.description
// 		},
// 		id: environment.paramsConfig.product.code,
// 			description: environment.paramsConfig.product.description
// 	},
// 	module: environment.moduleConfig.params,
// }, {
// 	id: 8,
// 		description: 'Factores de Ponderación/Reajuste de Siniestralidad',
// 		product: {
// 		branch: {
// 			id: environment.paramsConfig.branch.code,
// 				description: environment.paramsConfig.branch.description
// 		},
// 		id: environment.paramsConfig.product.code,
// 			description: environment.paramsConfig.product.description
// 	},
// 	module: environment.moduleConfig.params,
// },
// {
// 	id: 11,
// 		description: 'Rango de edades con dos valores',
// 	product: {
// 	branch: {
// 		id: environment.paramsConfig.branch.code,
// 			description: environment.paramsConfig.branch.description
// 	},
// 	id: environment.paramsConfig.product.code,
// 		description: environment.paramsConfig.product.description
// },
// 	module: environment.moduleConfig.params,
// },
// {
// 	id: 10,
// 		description: 'Rango de edad con un solo valor',
// 	product: {
// 	branch: {
// 		id: environment.paramsConfig.branch.code,
// 			description: environment.paramsConfig.branch.description
// 	},
// 	id: environment.paramsConfig.product.code,
// 		description: environment.paramsConfig.product.description
// },
// 	module: environment.moduleConfig.params,
// },

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import Auth from "@aws-amplify/auth";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(private router: Router, private auth: AuthService) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.commonOperation(state);
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		return this.commonOperation(state);
	}

	commonOperation(state: RouterStateSnapshot) {
		return Auth.currentAuthenticatedUser()
			.then((cognitoUser) => {
			const idToken = cognitoUser.getSignInUserSession().getIdToken();
			return !!idToken;
		})
			.catch(() => {
				window.location.href = environment.login + "?redirectTo=" + window.location.href.split("#")[0] + "%23" + state.url;
				return false;
			});
	}
}
